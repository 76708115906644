import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  Container,
  Nav,
  Navbar,
  Button,
  Row,
  Col,
  Stack,
  Offcanvas,
  Modal,
  ListGroup,
  Badge,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBaby,
  faBars,
  faBirthdayCake,
  faChevronLeft,
  faGift,
  faHeart,
  faSearch,
  faWeightHanging,
  faWineBottle,
} from "@fortawesome/free-solid-svg-icons";

import {
  UserMenuDesktop,
  UserMenuMobile,
} from "User/User_info/Menu/UserInfoHome";
import { useUser } from "../Provider/UserProvider";
import logo from "../static/logo/logo_en_160x40.svg";
import "./Header.css";
import { ProductSearchBar } from "Components/Search/component";

const categoryKeywords = {
  生日禮物: {
    mobileIcon: faBirthdayCake,
    imgs: [
      {
        link: "",
        img: "http://blog.nearmespecials.com/wp-content/uploads/2024/06/超出價格禮物.png",
      },
    ],
    words: [
      "手鍊",
      "保養品",
      "手袋",
      "香水",
      "手錶",
      "圍巾",
      "錢包",
      "吹風機",
      "行李箱",
    ],
  },
  情人節禮物: {
    mobileIcon: faHeart,
    imgs: [
      {
        link: "",
        img: "http://blog.nearmespecials.com/wp-content/uploads/2024/06/超出價格禮物.png",
      },
    ],
    words: [
      "高級手錶",
      "無線耳機",
      "電動剃鬚刀",
      "運動手環",
      "電子書閱讀器",
      "藍牙音響",
      "智能家居裝置",
      "香水",
      "手鍊",
      "耳環",
    ],
  },
  長輩送禮: {
    mobileIcon: faWineBottle,
    imgs: [
      {
        link: "",
        img: "http://blog.nearmespecials.com/wp-content/uploads/2024/06/超出價格禮物.png",
      },
    ],
    words: ["茶具", "茶葉", "人參食品", "按摩椅", "羊毛圍巾", "瓷器", "羽絨被"],
  },
  彌月禮物: {
    mobileIcon: faBaby,
    imgs: [
      {
        link: "",
        img: "http://blog.nearmespecials.com/wp-content/uploads/2024/06/超出價格禮物.png",
      },
    ],
    words: [
      "金銀紀念幣",
      "彌月蛋糕",
      "嬰兒衣物",
      "香氛蠟燭或擴香器",
      "奶瓶",
      "寶寶紀念品（例如腳印或手印套件）",
      "嬰兒乳液",
      "童裝",
      "高級紅酒或香檳",
      "手工香皂禮盒",
    ],
  },
  喬遷禮物: {
    mobileIcon: faWeightHanging,
    imgs: [
      {
        link: "",
        img: "http://blog.nearmespecials.com/wp-content/uploads/2024/06/超出價格禮物.png",
      },
    ],
    words: [
      "植物盆栽",
      "香氛蠟燭或擴香器",
      "刀具套裝",
      "雕塑",
      "咖啡機",
      "床單",
      "餐具",
      "智能燈泡",
      "空氣淨化器",
    ],
  },
};

const SCROLL_THRESHOLD = 30;

function throttle(func, limit) {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

const Header = () => {
  const headerContainer = useRef(null);
  const preY = useRef(0);
  const [showHeader, setShowHeader] = useState(true);
  const [canvasShow, setCanvasShow] = useState(false);
  const [pickCategory, setPickCategory] = useState("");
  const [searchCanvasShow, setSearchCanvasShow] = useState(false);
  const { isLogin, redirectAfterLogin } = useUser();
  const location = useLocation();
  const history = useHistory();
  const recommendSearch = ["項鍊", "手鍊"];

  useEffect(() => {
    const controlNavbar = () => {
      // const currentY = e.currentTarget.scrollY;
      const currentY = window.scrollY || document.documentElement.scrollTop;

      const dif = currentY - preY.current;

      if (currentY < 64) {
        setShowHeader(true);
      } else if (dif > SCROLL_THRESHOLD) {
        setShowHeader(false);
      } else if (dif < -SCROLL_THRESHOLD) {
        setShowHeader(true);
      }

      // remember current page location to use in the next move
      preY.current = currentY;
    };

    const thControl = throttle(controlNavbar, 10);

    window.addEventListener("scroll", thControl);

    return () => {
      window.removeEventListener("scroll", thControl);
    };
  }, []);

  useEffect(() => {
    if (window.scrollY < 100 && !showHeader) setShowHeader(true);
  }, [showHeader]);

  const isGiftResPage = useMemo(() => {
    return location.pathname.startsWith("/giftRes");
  }, [location]);

  // if (location.pathname === "/login") return null;

  return (
    <div
      className="fixed-top"
      style={{
        top: showHeader ? 0 : -200,
        transition: "top 0.4s ease-out",
      }}
    >
      {/* Mobile Header */}
      <section className="header text-center" ref={headerContainer}>
        <Row className="justify-content-between align-items-center px-3">
          <Col xs="auto">
            <Link to="/search" replace={location.pathname === "/search"}>
              <img
                src={logo}
                alt="NearMe"
                width="120rem"
                height="30rem"
                style={{ objectFit: "cover" }}
              />
            </Link>
          </Col>
          <Col></Col>
          <Col xs="auto">
            <Stack direction="horizontal" gap={3}>
              {!isGiftResPage && (
                <FontAwesomeIcon
                  icon={faSearch}
                  style={{ width: "24px", height: "24px" }}
                  className={`text-primary ms-auto`}
                  onClick={() => setSearchCanvasShow(true)}
                />
              )}
              <FontAwesomeIcon
                icon={faBars}
                onClick={() => setCanvasShow(true)}
                style={{ width: "24px", height: "24px" }}
                className="text-primary"
              />
            </Stack>
          </Col>
        </Row>

        <Offcanvas show={canvasShow} onHide={() => setCanvasShow(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>NearMe</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body onClick={() => setCanvasShow(false)}>
            {pickCategory ? (
              <ListGroup variant="flush">
                <strong
                  onClick={(e) => {
                    e.stopPropagation();
                    setPickCategory("");
                  }}
                  className="text-primary"
                >
                  <FontAwesomeIcon icon={faChevronLeft} /> 返回
                </strong>
                <br />
                {categoryKeywords[pickCategory].words.map((e) => (
                  <ListGroup.Item
                    key={e}
                    as={Link}
                    onClick={() => setPickCategory("")}
                    to={`/search?q=${e}`}
                    className="my-2"
                  >
                    {e}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : (
              <div>
                <Stack className="p-2" style={{ backgroundColor: "#f7f7f8" }}>
                  推薦商品
                </Stack>
                <ListGroup variant="flush">
                  {Object.keys(categoryKeywords).map((category) => (
                    <ListGroup.Item
                      className="my-2"
                      key={category}
                      onClick={(e) => {
                        e.stopPropagation();
                        setPickCategory(category);
                      }}
                    >
                      <Row>
                        <Col xs={1}>
                          <FontAwesomeIcon
                            icon={categoryKeywords[category].mobileIcon}
                          />
                        </Col>
                        <Col>{category}</Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <Stack className="p-2" style={{ backgroundColor: "#f7f7f8" }}>
                  個人資料
                </Stack>
                <UserMenuMobile />
              </div>
            )}
          </Offcanvas.Body>
        </Offcanvas>
        <Modal
          show={searchCanvasShow}
          onHide={() => setSearchCanvasShow(false)}
          fullscreen={"md-down"}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>NearMe</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProductSearchBar
              cb={(searchText) => {
                history.push(`/search?q=${searchText}`);
                setSearchCanvasShow(false);
              }}
            />
          </Modal.Body>
        </Modal>
      </section>

      {/* Desktop or Pad Header */}
      <section
        className="header-lg shadow"
        onMouseLeave={() => setPickCategory("")}
      >
        <Nav fill>
          <Container>
            <Row
              className="justify-content-between align-items-center pt-3"
              onMouseEnter={() => setPickCategory("")}
            >
              <Col xs="auto" className="text-center">
                <Navbar.Brand className="me-auto">
                  <Nav.Link as={Link} to="/search">
                    <img
                      src={logo}
                      alt="NearMe"
                      width="160rem"
                      height="40rem"
                    />
                  </Nav.Link>
                </Navbar.Brand>
              </Col>
              <Col xs="auto" md={4}>
                {!isGiftResPage && (
                  <>
                    <ProductSearchBar
                      cb={(searchText) =>
                        history.push(`/search?q=${searchText}`)
                      }
                    />
                    {/* <Stack direction="horizontal" gap={2}>
                      {recommendSearch.map((e) => (
                        <Link to={`/search?q=${e}`} key={e}>
                          <Badge bg="secondary">{e}</Badge>
                        </Link>
                      ))}
                    </Stack> */}
                  </>
                )}
              </Col>
              <Col xs="auto">
                <Row className="justify-content-between align-items-center">
                  <Col xs="auto">
                    <Nav.Link href="/" className="p-0 text-decoration-none">
                      <Button size="sm" variant="outline-primary">
                        了解NearMe
                      </Button>
                    </Nav.Link>
                  </Col>
                  <Col xs="auto">
                    <Nav.Link href="/FAQ" className="p-0">
                      <Button size="sm">如何使用</Button>
                    </Nav.Link>
                  </Col>
                  {isLogin || (
                    <Col xs="auto">
                      <Nav.Link href="/" className="p-0 text-decoration-none">
                        {/* <Button size="sm" variant="outline-primary"> */}
                        登入/註冊
                        {/* </Button> */}
                      </Nav.Link>
                    </Col>
                  )}
                  <Col xs="auto">
                    <UserMenuDesktop />
                  </Col>
                </Row>

                {/* <Stack gap={2} direction="horizontal"> */}

                {/* </Stack> */}
              </Col>
            </Row>
            {!isGiftResPage && (
              <Row className="justify-content-around">
                {Object.keys(categoryKeywords).map((e) => (
                  <Col
                    key={e}
                    className="recommend-category-title"
                    onMouseEnter={() => setPickCategory(e)}
                  >
                    <span>{e}</span>
                  </Col>
                ))}
              </Row>
            )}
          </Container>
          {pickCategory && (
            <div
              className="d-flex w-100 justify-content-center"
              style={{ position: "relative" }}
            >
              <div
                className={`w-100 shadow`}
                style={{ position: "absolute", backgroundColor: "#FFFFFF" }}
              >
                <Stack className="col-md-8 mx-auto py-3">
                  <img
                    src={categoryKeywords[pickCategory].imgs[0].img}
                    style={{
                      width: "100%",
                      height: "100px",
                      objectFit: "cover",
                      borderRadius: "6px",
                    }}
                  />
                  <Row xs={2}>
                    {categoryKeywords[pickCategory]?.words?.map((e) => (
                      <Col key={e}>
                        <Link
                          to={`/search?q=${e}`}
                          className="recommend-keyword"
                          onClick={() => setPickCategory("")}
                        >
                          {e}
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Stack>
              </div>
            </div>
          )}
        </Nav>
      </section>
    </div>
  );
};

export { Header };
